import axios from 'axios';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { proteins, carbs, fats, greenVegetables } from '../data/foods';

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['x-auth-token'] = token;
  } else {
    delete axios.defaults.headers.common['x-auth-token'];
  }
};

export const fetchData = async (url, config, body) => {
  const response = await axios.get(url, config, body);
  if (response.status >= 200 && response.status < 300) {
    return response.text().then((text) => {
      try {
        //if response is not json, will throw and error and return text
        const data = JSON.parse(text);
        return data;
      } catch (err) {
        return text;
      }
    });
    // const data = await response.isJson() ? response.json() : response;
    // return data;
  } else {
    throw response;
  }
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const convertMeasurement = (measurement, amount) => {
  let oz;
  let grams;
  let lb;
  let qty;

  if (measurement === 'oz') {
    oz = Number(amount);
    grams = Number((amount * 28.34952).toFixed(1));
    lb = Number((amount / 16).toFixed(1));
    qty = 0;
  } else if (measurement === 'grams') {
    oz = Number((amount / 28.34952).toFixed(1));
    grams = Number(amount);
    lb = Number((amount * 0.0022).toFixed(1));
    qty = 0;
  } else if (measurement === 'lb') {
    oz = Number((amount * 16).toFixed(1));
    grams = Number((amount * 453.592).toFixed(1));
    lb = Number(amount);
    qty = 0;
  } else if (measurement === 'qty') {
    oz = 0;
    grams = 0;
    lb = 0;
    qty = Number(amount);
  }

  return { oz: oz, grams: grams, lb: lb, qty: qty };
};

export const formatCustomer = (customer) => {
  return (
    <div style={{ display: 'grid' }}>
      <span style={{ lineHeight: '15px' }}>{customer.name}</span>
      <a style={{ lineHeight: '15px' }}>{customer.email}</a>
      <p></p>
    </div>
  );
};

export const isValidEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const addToMailChimp = async (form, tags) => {
  const url = '/api/mailchimp/subscribe';

  if (!form.email) {
    throw new Error('Email is required to subscribe.');
  }

  try {
    const response = await axios.post(url, {
      email: form.email.trim().toLowerCase(),
      name: form.name ? capitalize(form.name.trim()) : undefined,
      lastName: form.lastName ? capitalize(form.lastName.trim()) : undefined,
      tags: Array.isArray(tags) ? tags : [],
    });
    return response.data;
  } catch (error) {
    console.error(
      'Error adding to MailChimp:',
      error.response?.data || error.message
    );
    throw error;
  }
};

export const feetToCentimeters = (feet, inches = 0) => {
  const totalInches = feet * 12 + inches;
  const centimeters = totalInches * 2.54;
  return Math.round(centimeters);
};

export const shortenText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }

  // Subtract 3 from maxLength to make room for the ellipsis (...)
  const truncatedText = text.substring(0, maxLength - 3);

  // Add the ellipsis (...) at the end
  return truncatedText + '...';
};

export const starRating = (rating) => {
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        // Render a filled star if the current star is less than or equal to the rating
        stars.push(
          <AiFillStar key={i} className='star' style={{ color: 'gold' }} />
        );
      } else {
        // Render an empty star if the current star is greater than the rating
        stars.push(<AiOutlineStar key={i} className='star' />);
      }
    }
    return stars;
  };

  return <div className='star-rating'>{renderStars()}</div>;
};

export const generateImperialHeightOptions = (t) => {
  const options = [];
  for (let feet = 3; feet <= 9; feet++) {
    const maxInches = feet === 9 ? 0 : 11; // For 9 feet, allow only 0 inches
    for (let inches = 0; inches <= maxInches; inches++) {
      const key = `${feet}_${inches}`;
      options.push({
        name: t(`questions.height.options.${key}`),
        value: `${feet}'${inches}`,
      });
    }
  }
  return options;
};

export const generateMetricHeightOptions = (t) => {
  const options = [];
  for (let cm = 90; cm <= 210; cm++) {
    options.push({
      name: t('questions.height.metricOption', { cm }),
      value: `${cm}`,
    });
  }
  return options;
};

export const getMealDistribution = (numMeals, largestMeal) => {
  let distribution;

  // Base distributions based on number of meals
  switch (numMeals) {
    case 3:
      distribution = [
        { name: 'breakfast', percentage: 33 },
        { name: 'lunch', percentage: 33 },
        { name: 'dinner', percentage: 33 },
      ];
      break;
    case 4:
      distribution = [
        { name: 'breakfast', percentage: 25 },
        { name: 'snack', percentage: 25 },
        { name: 'lunch', percentage: 25 },
        { name: 'dinner', percentage: 25 },
      ];
      break;
    case 5:
      distribution = [
        { name: 'breakfast', percentage: 20 },
        { name: 'snack', percentage: 15 },
        { name: 'lunch', percentage: 25 },
        { name: 'snack', percentage: 15 },
        { name: 'dinner', percentage: 25 },
      ];
      break;
    case 6:
      distribution = [
        { name: 'breakfast', percentage: 20 },
        { name: 'snack', percentage: 10 },
        { name: 'lunch', percentage: 20 },
        { name: 'snack', percentage: 10 },
        { name: 'dinner', percentage: 20 },
        { name: 'snack', percentage: 20 },
      ];
      break;
    default:
      distribution = [];
  }

  // Adjust the distribution based on the `largestMeal` selection
  if (largestMeal === 'Breakfast') {
    return adjustLargestMeal(distribution, 'breakfast');
  } else if (largestMeal === 'Lunch') {
    return adjustLargestMeal(distribution, 'lunch');
  } else if (largestMeal === 'Dinner') {
    return adjustLargestMeal(distribution, 'dinner');
  }

  // If `Distribute equally`, return the base distribution
  return distribution;
};

export const adjustLargestMeal = (distribution, largestMealName) => {
  const extraPercentage = 10; // Extra percentage for largest meal
  const largestMeal = distribution.find(
    (meal) => meal.name === largestMealName
  );

  if (!largestMeal) return distribution; // In case largestMealName doesn't match any meal name

  // Step 1: Increase the largest meal's percentage
  largestMeal.percentage += extraPercentage;

  // Step 2: Distribute the extraPercentage reduction across other meals
  const otherMeals = distribution.filter(
    (meal) => meal.name !== largestMealName
  );
  const reductionPerMeal = extraPercentage / otherMeals.length;

  // Reduce each other meal's percentage by the calculated reduction
  otherMeals.forEach((meal) => {
    meal.percentage -= reductionPerMeal;
  });

  // Step 3: Adjust to ensure total percentage is exactly 100
  const totalPercentage = distribution.reduce(
    (sum, meal) => sum + meal.percentage,
    0
  );
  const adjustment = 100 - totalPercentage;

  // Apply the adjustment to the first meal in the distribution (or any one meal)
  distribution[0].percentage += adjustment;

  console.log('Distribution:', distribution);
  return distribution;
};

export const filterFoodsByDiet = (foods, dietType) => {
  return foods.filter((food) => food.dietType.includes(dietType.toLowerCase()));
};

export const selectFoodsForMeal = (
  targetMacros,
  proteins,
  carbs,
  fats,
  vegetables,
  mealType
) => {
  const selectedFoods = [];

  const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

  const proteinFood = getFoodForMacros(
    targetMacros.protein,
    shuffleArray(proteins),
    'protein',
    mealType
  );
  selectedFoods.push(proteinFood);

  const carbFood = getFoodForMacros(
    targetMacros.carbs,
    shuffleArray(carbs),
    'carbs',
    mealType
  );
  selectedFoods.push(carbFood);

  const fatFood = getFoodForMacros(
    targetMacros.fats,
    shuffleArray(fats),
    'fats',
    mealType
  );
  selectedFoods.push(fatFood);

  const vegetableFood = getFoodForMacros(
    Math.min(targetMacros.calories * 0.05, 20), // Limit to 5% of calories or max 20 calories
    shuffleArray(vegetables),
    'calories',
    mealType
  );
  if (vegetableFood) selectedFoods.push(vegetableFood);

  return selectedFoods;
};

function getFoodForMacros(targetAmount, foodList, macroType, mealType) {
  for (const food of foodList) {
    // Only consider foods that are suitable for the current meal type and contain the required macro
    if (food.recommendedFor.includes(mealType) && food.macros[macroType] > 0) {
      // Calculate the number of servings required to reach the target macro amount
      let servings = targetAmount / food.macros[macroType];

      // Round servings to the nearest 0.5 (for user-friendly amounts)
      servings = Math.round(servings * 2) / 2;

      // Only include food if the servings are at least 0.5 for practicality
      if (servings > 0) {
        // Calculate macros based on the rounded servings
        let foodSelected = {
          name: food.name,
          servingSize: servings,
          servingType: food.servingType,
          category: food.category,
          macros: {
            calories: servings * food.macros.calories,
            protein: servings * food.macros.protein,
            carbs: servings * food.macros.carbs,
            fats: servings * food.macros.fats,
          },
        };

        // Log the selected food for verification
        console.log('foodSelected:', JSON.stringify(foodSelected));
        return foodSelected;
      }
    }
  }

  return null;
}

export const generateMealPlan = (macros, dietType, numMeals, largestMeal) => {
  const tolerance = 50; // Allowable mismatch in calories
  const calculatedCalories =
    macros.protein * 4 + macros.carbs * 4 + macros.fats * 9;

  if (Math.abs(calculatedCalories - macros.calories) > tolerance) {
    console.warn(
      'Calorie mismatch detected. Calculated:',
      calculatedCalories,
      'Provided:',
      macros.calories
    );
  }

  const mealDistribution = getMealDistribution(numMeals, largestMeal);
  const proteinOptions = filterFoodsByDiet(proteins, dietType);
  const carbOptions = filterFoodsByDiet(carbs, dietType);
  const fatOptions = filterFoodsByDiet(fats, dietType);
  const vegetableOptions = greenVegetables;

  // Initialize total macros
  const totalMacros = { calories: 0, protein: 0, carbs: 0, fats: 0 };

  const mealPlan = mealDistribution
    .map((meal) => {
      const targetMacros = {
        calories: (macros.calories * meal.percentage) / 100,
        protein: (macros.protein * meal.percentage) / 100,
        carbs: (macros.carbs * meal.percentage) / 100,
        fats: (macros.fat * meal.percentage) / 100,
      };

      const foods = selectFoodsForMeal(
        targetMacros,
        proteinOptions,
        carbOptions,
        fatOptions,
        vegetableOptions,
        meal.name
      ).filter((food) => food !== null);

      // Skip empty meals
      if (foods.length === 0) return null;

      // Calculate actual macros for this meal based on selected foods
      const mealMacros = foods.reduce(
        (acc, food) => {
          acc.calories += food?.macros?.calories || 0;
          acc.protein += food?.macros?.protein || 0;
          acc.carbs += food?.macros?.carbs || 0;
          acc.fats += food?.macros?.fats || 0;

          return acc;
        },
        { calories: 0, protein: 0, carbs: 0, fats: 0 }
      );

      // Add meal macros to total macros
      totalMacros.calories += mealMacros.calories;
      totalMacros.protein += mealMacros.protein;
      totalMacros.carbs += mealMacros.carbs;
      totalMacros.fats += mealMacros.fats;

      return {
        name: meal.name,
        foods: foods,
        macros: {
          calories: Math.round(mealMacros.calories),
          protein: Math.round(mealMacros.protein),
          carbs: Math.round(mealMacros.carbs),
          fats: Math.round(mealMacros.fats),
        },
      };
    })
    .filter((meal) => meal !== null); // Remove null meals

  // **Fine-tune the meal plan macros to better match the targets**
  const scaleFactorProtein = macros.protein / totalMacros.protein;
  const scaleFactorCarbs = macros.carbs / totalMacros.carbs;
  const scaleFactorFats = macros.fat / totalMacros.fats;

  mealPlan.forEach((meal) => {
    meal.foods.forEach((food) => {
      // Preserve the original serving size and macros if they are not already preserved
      if (!food.originalServingSize) {
        food.originalServingSize = food.servingSize;
        food.originalMacros = { ...food.macros }; // Copy the original macros
      }

      // Calculate the average scale factor for serving size adjustment
      const averageScaleFactor =
        (scaleFactorProtein + scaleFactorCarbs + scaleFactorFats) / 3;

      // Adjust serving sizes proportionally and round to the nearest 0.5 for user-friendliness
      food.servingSize =
        Math.round(food.originalServingSize * averageScaleFactor * 2) / 2;

      // Recalculate food macros based on the new serving size
      const servingScalingFactor = food.servingSize / food.originalServingSize;

      // Adjust macros based on the new serving size (avoid rounding until final calculation)
      food.macros.protein = food.originalMacros.protein * servingScalingFactor;
      food.macros.carbs = food.originalMacros.carbs * servingScalingFactor;
      food.macros.fats = food.originalMacros.fats * servingScalingFactor;

      // Calculate calories based on adjusted macros without rounding each macro
      food.macros.calories =
        food.macros.protein * 4 + food.macros.carbs * 4 + food.macros.fats * 9;

      // Round the macros after all calculations are done
      food.macros.protein = Math.round(food.macros.protein);
      food.macros.carbs = Math.round(food.macros.carbs);
      food.macros.fats = Math.round(food.macros.fats);
      food.macros.calories = Math.round(food.macros.calories);
    });

    // Update meal macros based on adjusted food macros
    meal.macros.protein = meal.foods.reduce(
      (sum, food) => sum + food.macros.protein,
      0
    );
    meal.macros.carbs = meal.foods.reduce(
      (sum, food) => sum + food.macros.carbs,
      0
    );
    meal.macros.fats = meal.foods.reduce(
      (sum, food) => sum + food.macros.fats,
      0
    );
    meal.macros.calories = meal.foods.reduce(
      (sum, food) => sum + food.macros.calories,
      0
    );
  });

  // Recalculate and round total macros
  totalMacros.protein = Math.round(
    mealPlan.reduce((sum, meal) => sum + meal.macros.protein, 0)
  );
  totalMacros.carbs = Math.round(
    mealPlan.reduce((sum, meal) => sum + meal.macros.carbs, 0)
  );
  totalMacros.fats = Math.round(
    mealPlan.reduce((sum, meal) => sum + meal.macros.fats, 0)
  );
  totalMacros.calories = Math.round(
    mealPlan.reduce((sum, meal) => sum + meal.macros.calories, 0)
  );

  console.log(JSON.stringify(mealPlan, totalMacros));
  return { mealPlan, totalMacros };
};

export const convertToUserFriendly = (ingredient, totalServingSize) => {
  const { servingType, category, name } = ingredient;
  let userFriendlyAmount = totalServingSize;
  let userFriendlyUnit = servingType || 'unknown';

  switch (servingType) {
    case 'oz': {
      if (name.toLowerCase().includes('yogurt')) {
        userFriendlyAmount = (totalServingSize / 32).toFixed(1);
        userFriendlyUnit = 'containers (32 oz)';
      } else if (category === 'protein' || category === 'carbs') {
        userFriendlyAmount = (totalServingSize / 16).toFixed(1);
        userFriendlyUnit = 'lbs';
      } else if (category === 'greenVegetables') {
        userFriendlyAmount = (totalServingSize / 8).toFixed(1);
        userFriendlyUnit = 'bunches/bags (8 oz)';
      } else {
        userFriendlyAmount = totalServingSize.toFixed(1);
        userFriendlyUnit = 'oz';
      }
      break;
    }
    case 'tbsp': {
      if (name.toLowerCase().includes('oil')) {
        userFriendlyAmount = 1;
        userFriendlyUnit = 'small bottle';
      } else if (name.toLowerCase() === 'grass-fed butter') {
        userFriendlyAmount = (totalServingSize / 8).toFixed(1);
        userFriendlyUnit = 'sticks';
      } else {
        userFriendlyAmount = totalServingSize.toFixed(1);
        userFriendlyUnit = 'tbsp';
      }
      break;
    }
    case 'unit':
    case 'slice':
    case 'patty':
    case 'scoop': {
      userFriendlyAmount = Math.ceil(totalServingSize);
      userFriendlyUnit =
        servingType + (Math.ceil(totalServingSize) > 1 ? 's' : '');
      break;
    }
    default: {
      userFriendlyAmount = totalServingSize.toFixed(2);
      break;
    }
  }

  return `- ${name} - ${userFriendlyAmount} ${userFriendlyUnit}`;
};

export default setAuthToken;
