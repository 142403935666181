export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR';
export const CLEAR_SUCCESS_MESSAGE = 'CLEAR_SUCCESS_MESSAGE';
export const OPEN_REVIEW_MODAL = 'OPEN_REVIEW_MODAL';
export const HANDLE_GLOBAL_MODAL = 'HANDLE_GLOBAL_MODAL';
export const SET_FOOTER_NAVIGATION = 'SET_FOOTER_NAVIGATION';
export const SET_MOBILE_VIEW = 'SET_MOBILE_VIEW';
export const HANDLE_DRAWER_MENU = 'HANDLE_DRAWER_MENU';
export const SET_MACRONUTRIENT_ANSWERS = 'SET_MACRONUTRIENT_ANSWERS';

export const SAVE_MACRONUTRIENT_ANSWERS = 'SAVE_MACRONUTRIENT_ANSWERS';
export const SAVE_MACRONUTRIENT_ANSWERS_SUCCESS =
  'SAVE_MACRONUTRIENT_ANSWERS_SUCCESS';
export const SAVE_MACRONUTRIENT_ANSWERS_ERROR =
  'SAVE_MACRONUTRIENT_ANSWERS_ERROR';

export const GET_MACRONUTRIENT_ANSWERS = 'GET_MACRONUTRIENT_ANSWERS';
export const GET_MACRONUTRIENT_ANSWERS_SUCCESS =
  'GET_MACRONUTRIENT_ANSWERS_SUCCESS';
export const GET_MACRONUTRIENT_ANSWERS_ERROR =
  'GET_MACRONUTRIENT_ANSWERS_ERROR';
export const HANDLE_WELCOME_MODAL = 'HANDLE_WELCOME_MODAL';
