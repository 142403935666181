import React, {
  useState,
  useEffect,
  useMemo,
  createRef,
  useCallback,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from './components/NavBar';
import { Footer } from './components/Footer';
import IndexRoute from './routes/index';
import setAuthToken from './helpers/utils';
import ReactGA from 'react-ga4';
import Hotjar from '@hotjar/browser';
import { GlobalModal } from './components/Modal';
import { footerLinksConfig } from './data/footerLinks';
import { setFooterNavigation } from './ducks/Global/actions';
import { setMobileView } from './ducks/Global/actions';
import { DrawerMenu } from './components/Drawer/index';
import ScrollToTop from './components/ScrollToTop';
import { loadUser } from './ducks/Auth/actions';
import { RegisterModal } from './components/AuthModal/register';
import { WelcomeModal } from './components/AuthModal/welcome';
import './index.css';

const siteId = 3690945;
const hotjarVersion = 6;
ReactGA.initialize('G-V5H4JJZZHV');
Hotjar.init(siteId, hotjarVersion);

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobileView = useSelector((state) => state.global.isMobileView);
  const isDrawerOpen = useSelector((state) => state.global.isDrawerOpen);
  const isVisible = useSelector((state) => state.global.isLegalModalOpen);
  const legalTopic = useSelector((state) => state.global.legalTopic);
  const [hasRedirected, setHasRedirected] = useState(false);
  const isAuthModalVisible = useSelector(
    (state) => state.auth.isAuthModalVisible
  );
  const auth = useSelector((state) => state.auth);
  // const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // Memoize the links array
  const links = useMemo(
    () => footerLinksConfig[location.pathname] || [],
    [location.pathname]
  );

  const sectionsRefs = useMemo(() => {
    const refs = {};
    links.forEach((link) => {
      refs[link.link] = createRef();
    });
    return refs;
  }, [links]);

  useEffect(() => {
    if (
      auth.isAuthenticated &&
      auth.user &&
      auth.user.role !== 'admin' &&
      !hasRedirected
    ) {
      if (location.pathname === '/') {
        navigate('/tools/macronutrient-calculator', { replace: true });
        setHasRedirected(true);
      } else if (
        location.pathname !== '/tools/macronutrient-calculator' &&
        location.pathname !== '/transformation-program' &&
        location.pathname !== '/about'
      ) {
        // Prevent unintended redirection
        setHasRedirected(false);
      }
    }
  }, [
    auth.isAuthenticated,
    auth.user,
    location.pathname,
    navigate,
    hasRedirected,
  ]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const isResetPassword = location.pathname.includes('/reset-password');

    if (token && !auth.user && !isResetPassword) {
      setAuthToken(token);
      dispatch(loadUser());
    }
  }, [dispatch, auth.user, location.pathname]);

  useEffect(() => {
    dispatch(setFooterNavigation(links));
  }, [dispatch, links]);

  useEffect(() => {
    checkWindowSize();
    window.addEventListener('resize', checkWindowSize);

    return () => {
      window.removeEventListener('resize', checkWindowSize);
    };
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    ReactGA.gtag('config', 'G-V5H4JJZZHV', {
      page_path: currentPath,
    });
  }, [location]);

  const handleScroll = useCallback((ref) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/' && location.state?.scrollTo) {
      const ref = sectionsRefs[location.state.scrollTo];
      if (ref && ref.current) {
        handleScroll(sectionsRefs[location.state.scrollTo]);
        navigate(location.pathname, { replace: true, state: {} });
      }
    }
  }, [handleScroll, location, sectionsRefs, navigate]);

  const checkWindowSize = () => {
    if (window.innerWidth <= 768) {
      dispatch(setMobileView(true));
    } else {
      dispatch(setMobileView(false));
    }
  };

  return (
    <div className='app'>
      <ScrollToTop />
      <GlobalModal isVisible={isVisible} content={legalTopic} />
      <RegisterModal isVisible={isAuthModalVisible} />
      <WelcomeModal />
      {/* {location.pathname.includes('results') && <TopBanner />} */}
      {/* {location.pathname !== '/transformation-program' && */}
      {location.pathname !== '/login' &&
      location.pathname !== '/register' &&
      location.pathname !== '/reset-password' ? (
        <NavBar isMobileView={isMobileView} isDrawerOpen={isDrawerOpen} />
      ) : null}
      {isMobileView && <DrawerMenu />}
      <IndexRoute sectionsRefs={sectionsRefs} handleScroll={handleScroll} />
      {location.pathname !== '/login' &&
      location.pathname !== '/register' &&
      location.pathname !== '/reset-password' ? (
        <Footer sectionsRefs={sectionsRefs} />
      ) : null}
    </div>
  );
};

export default App;
