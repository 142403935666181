import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

const MacroLoading = ({ onComplete }) => {
  const [step, setStep] = useState(0);
  const auth = useSelector((state) => state.auth);

  const loadingSteps = [
    'Analyzing your input...',
    'Matching activity levels and goals...',
    'Calculating your personalized macros...',
  ];

  useEffect(() => {
    const interval = setInterval(
      () => {
        setStep((prevStep) => {
          if (prevStep < loadingSteps.length - 1) {
            return prevStep + 1;
          } else {
            clearInterval(interval);
            onComplete(); // Trigger the next action when loading completes
            return prevStep;
          }
        });
      },
      auth && auth.user ? 600 : 2000
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={styles.fullScreen}>
      <div style={styles.centeredContainer}>
        <Spin size='large' style={styles.loader} />
        <motion.div
          key={step}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.5 }}
          style={styles.text}
        >
          {loadingSteps[step]}
        </motion.div>
        {/* Progress bar */}
        <div style={styles.progressBarContainer}>
          {loadingSteps.map((_, index) => (
            <div
              key={index}
              style={{
                ...styles.progressStep,
                backgroundColor: index <= step ? '#FA04A4' : '#555', // Highlight completed steps
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

// Styles for the loading screen
const styles = {
  fullScreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    zIndex: 9999, // Ensure it's on top of everything
  },
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  text: {
    color: 'white',
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '20px',
    textAlign: 'center',
  },
  loader: {
    color: '#FA04A4',
    marginBottom: '20px',
  },
  progressBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%', // Adjust the width of the progress bar
    marginTop: '20px',
    gap: '5px',
  },
  progressStep: {
    flex: 1,
    height: '10px',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease',
  },
};

export default MacroLoading;
