import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Select, Modal, Input } from 'antd';
import { capitalize } from '../../helpers/utils';
import { motion } from 'framer-motion';
import { IoIosArrowDown } from 'react-icons/io';
import {
  setMacronutrientAnswers,
  handleGlobalModal,
} from '../../ducks/Global/actions';
import { useDispatch, useSelector } from 'react-redux';
import { generateMealPlan } from '../../helpers/utils';
import { MealPlanLoading } from '../../components/MacroNutrientCalculatorComponent/customMealPlanLoading';
import { IoIosArrowBack } from 'react-icons/io';
import { lowCalories } from '../../data/warnings';

export const MacroResults = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cardOpen, setCardOpen] = useState({
    bmr: false,
    tdee: false,
    macros: false,
    water: false,
  });
  const macronutrientAnswers = useSelector(
    (state) => state.global.macronutrientAnswers
  );
  const [percentageError, setPercentageError] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [mealPlanAnswers, setMealPlanAnswers] = useState({
    numberOfMeals: 3,
    dietType: 'Standard',
    largestMeal: 'Distribute equally',
  });
  const [macroResults, setMacroResults] = useState(null);
  const [selectedMacros, setSelectedMacros] = useState({});
  const [isGeneratingMealPlan, setIsGeneratingMealPlan] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const auth = useSelector((state) => state.auth);
  const [macroClasses, setMacroClasses] = useState({
    calories: '',
    protein: '',
    carbs: '',
    fat: '',
  });
  const userGender = useSelector(
    (state) => state.global.macronutrientAnswers.gender
  );
  const prevGoalRef = useRef(null);

  const { results = {}, isMobileView } = location.state || {};

  const selectedGoal = useMemo(() => {
    return macroResults?.macrosForEachGoal
      ? Object.entries(macroResults.macrosForEachGoal).find(
          ([, goal]) => goal.isSelected
        )
      : null;
  }, [macroResults]);

  useEffect(() => {
    setMacroResults(results);
  }, [results]);

  useEffect(() => {
    if (selectedGoal) {
      const [key, goal] = selectedGoal;
      setSelectedMacros((prev) => {
        // Only update state if values have changed
        const isDifferent =
          prev.goal !== capitalize(key.split('-').join(' ')) ||
          prev.protein !== goal.protein ||
          prev.carbs !== goal.carbs ||
          prev.fat !== goal.fat ||
          prev.calories !== goal.calories;

        if (isDifferent) {
          return {
            goal: capitalize(key.split('-').join(' ')),
            protein: goal.protein,
            carbs: goal.carbs,
            fat: goal.fat,
            calories: goal.calories,
          };
        }
        return prev; // Avoid unnecessary state updates
      });
    }
  }, [selectedGoal]);

  useEffect(() => {
    if (macroResults) {
      const selectedGoal = Object.values(macroResults.macrosForEachGoal).find(
        (goal) => goal.isSelected
      );

      if (selectedGoal) {
        const prevGoal = prevGoalRef.current || {}; // Get previous goal, default to an empty object
        const newClasses = {};

        if (selectedGoal.calories !== prevGoal.calories) {
          newClasses.calories = 'macro-value-change';
        }
        if (selectedGoal.protein !== prevGoal.protein) {
          newClasses.protein = 'macro-value-change';
        }
        if (selectedGoal.carbs !== prevGoal.carbs) {
          newClasses.carbs = 'macro-value-change';
        }
        if (selectedGoal.fat !== prevGoal.fat) {
          newClasses.fat = 'macro-value-change';
        }

        setMacroClasses(newClasses);

        // Update prevGoalRef to the current selectedGoal for the next render
        prevGoalRef.current = selectedGoal;

        // Reset animation classes after animation ends
        const timeout = setTimeout(() => {
          setMacroClasses({
            calories: '',
            protein: '',
            carbs: '',
            fat: '',
          });
        }, 500);

        return () => clearTimeout(timeout);
      }
    }
  }, [macroResults]);

  useEffect(() => {
    if (!macroResults || !selectedGoal) return;
    let isLowCalories =
      macroResults && macroResults.bmr > selectedGoal[1]?.calories;
    if (
      isLowCalories &&
      window.location.pathname === '/tools/macronutrient-calculator/results'
    ) {
      dispatch(
        handleGlobalModal(
          true,
          lowCalories(macroResults.bmr, selectedGoal[1].calories, userGender)
        )
      );
    }
  }, [macroResults, selectedGoal, dispatch, userGender]);

  if (!results.bmr) {
    return <p>Loading results...</p>;
  }

  if (!macroResults || !macroResults.macrosForEachGoal) {
    return <div>Error: Macro results not available. Please try again.</div>;
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const buttonVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, delay: 0.5 },
    },
  };

  const items = [
    {
      value: 'balanced',
      label: t('tabs.balanced.label'),
      explanation: t('tabs.balanced.explanation'),
    },
    {
      value: 'low-carb',
      label: t('tabs.low-carb.label'),
      explanation: t('tabs.low-carb.explanation'),
    },
    {
      value: 'high-carb',
      label: t('tabs.high-carb.label'),
      explanation: t('tabs.high-carb.explanation'),
    },
    {
      value: 'high-protein',
      label: t('tabs.high-protein.label'),
      explanation: t('tabs.high-protein.explanation'),
    },
    {
      value: 'ketogenic',
      label: t('tabs.ketogenic.label'),
      explanation: t('tabs.ketogenic.explanation'),
    },
    {
      value: 'custom',
      label: t('tabs.custom.label'),
      explanation: t('tabs.custom.explanation'),
    },
  ];

  const mealsPerDay = [
    {
      key: 3,
      value: 3,
    },
    {
      key: 4,
      value: 4,
    },
    {
      key: 5,
      value: 5,
    },
    {
      key: 6,
      value: 6,
    },
  ];

  const dietType = [
    {
      key: 1,
      value: 'Standard',
    },
    {
      key: 2,
      value: 'Vegetarian',
    },
  ];

  const largestMeal = [
    {
      key: 1,
      value: 'Distribute equally',
    },
    {
      key: 2,
      value: 'Breakfast',
    },
    {
      key: 3,
      value: 'Lunch',
    },
    {
      key: 4,
      value: 'Dinner',
    },
  ];

  const setCustomMacros = (macroType, percentage) => {
    const updatedMacros = {
      ...macronutrientAnswers.customMacros,
      [macroType]: percentage,
    };

    // Calculate the remaining macro percentage
    const totalPercentage =
      updatedMacros.protein + updatedMacros.carbs + updatedMacros.fat;

    setPercentageError(totalPercentage !== 100);

    dispatch(
      setMacronutrientAnswers({
        ...macronutrientAnswers,
        customMacros: updatedMacros,
      })
    );

    setMacroResults((prevResults) => ({
      ...prevResults,
      macrosForEachGoal: Object.fromEntries(
        Object.entries(prevResults.macrosForEachGoal).map(([key, goal]) => [
          key,
          {
            ...goal,
            ...calculateMacrosWithCustomValues(goal.calories, updatedMacros),
          },
        ])
      ),
    }));
  };

  const calculateMacrosWithCustomValues = (calories, customMacros) => {
    return {
      protein: Math.round(((customMacros.protein / 100) * calories) / 4),
      carbs: Math.round(((customMacros.carbs / 100) * calories) / 4),
      fat: Math.round(((customMacros.fat / 100) * calories) / 9),
    };
  };

  const renderCustomMacrosInput = () => {
    if (macronutrientAnswers.isCustomMacros) {
      return (
        <div className='mb-20'>
          <div className='custom-macros-input-container'>
            {Object.entries(macronutrientAnswers.customMacros).map((macro) => (
              <Input
                onChange={(e) =>
                  setCustomMacros(macro[0], Number(e.target.value))
                }
                value={macronutrientAnswers.customMacros[macro[0]]}
                type='number'
                addonAfter={`${capitalize(macro[0])} %`}
                maxLength={2}
              />
            ))}
          </div>
          {percentageError ? (
            <p
              className='mb-20'
              style={{ textAlign: 'center', color: 'red', fontSize: '14px' }}
            >
              Macro percentages must equal 100%
            </p>
          ) : null}
        </div>
      );
    }
  };

  const setMacroPreference = (value) => {
    if (!macroResults) return;

    const calculateMacros = (
      calories,
      proteinPercent,
      carbPercent,
      fatPercent
    ) => ({
      protein: Math.round((proteinPercent * calories) / 4),
      carbs: Math.round((carbPercent * calories) / 4),
      fat: Math.round((fatPercent * calories) / 9),
    });

    // Update macros for each goal based on the selected preference
    const updatedMacrosForEachGoal = Object.fromEntries(
      Object.entries(macroResults.macrosForEachGoal).map(([key, goal]) => {
        const calories = goal.calories;
        let updatedGoal;

        switch (value) {
          case 'balanced':
            updatedGoal = calculateMacros(calories, 0.35, 0.35, 0.3);
            break;
          case 'low-carb':
            updatedGoal = calculateMacros(calories, 0.4, 0.2, 0.4);
            break;
          case 'high-carb':
            updatedGoal = calculateMacros(calories, 0.3, 0.5, 0.2);
            break;
          case 'high-protein':
            updatedGoal = calculateMacros(calories, 0.4, 0.3, 0.3);
            break;
          case 'ketogenic':
            updatedGoal = calculateMacros(calories, 0.4, 0.1, 0.5);
            break;
          case 'custom':
            updatedGoal = calculateMacros(
              calories,
              macronutrientAnswers.customMacros.protein / 100,
              macronutrientAnswers.customMacros.carbs / 100,
              macronutrientAnswers.customMacros.fat / 100
            );
            break;
          default:
            updatedGoal = calculateMacros(calories, 0.35, 0.35, 0.3);
            break;
        }

        return [
          key,
          {
            ...goal,
            ...updatedGoal,
          },
        ];
      })
    );

    // Update both macroResults and macronutrientAnswers
    setMacroResults((prevResults) => ({
      ...prevResults,
      macrosForEachGoal: updatedMacrosForEachGoal,
    }));

    // Dispatch updated macronutrientAnswers to reflect selected preference
    dispatch(
      setMacronutrientAnswers({
        ...macronutrientAnswers,
        macroPreference: value,
        isCustomMacros: value === 'custom',
      })
    );
  };

  const renderSelectedGoalAndMacros = () => {
    return (
      macroResults &&
      Object.entries(macroResults.macrosForEachGoal).map(([key, goal]) => {
        return (
          goal.isSelected && (
            <div
              className='global-card dark'
              style={{
                border: '1px solid #f9f9f9',
                background:
                  'linear-gradient(90deg, rgba(39, 97, 241, 0.2), rgba(255, 0, 162, 0.2))',
                padding: `${isMobileView ? '18px 10px' : '18px'}`,
                borderRadius: '20px',
              }}
            >
              <div className='flex space-between'>
                <p className='eyebrow-text white mb-10'>
                  {capitalize(key.split('-').join(' '))}
                </p>
              </div>
              <div className='flex space-between'>
                <div className='grid justify-center'>
                  <p className={`macro-number bold ${macroClasses.calories}`}>
                    {goal.calories}
                  </p>
                  <p className='subtitle'>Calories</p>
                </div>
                <div className='grid justify-center'>
                  <p className={`macro-number bold ${macroClasses.protein}`}>
                    {goal.protein}g
                  </p>
                  <p className='subtitle'>Protein</p>
                </div>
                <div className='grid justify-center'>
                  <p className={`macro-number bold ${macroClasses.carbs}`}>
                    {goal.carbs}g
                  </p>
                  <p className='subtitle'>Carbs</p>
                </div>
                <div className='grid justify-center'>
                  <p className={`macro-number bold ${macroClasses.fat}`}>
                    {goal.fat}g
                  </p>
                  <p className='subtitle'>Fat</p>
                </div>
              </div>
            </div>
          )
        );
      })
    );
  };

  const mealPlanQuestions = [
    {
      label: 'Preferred meals per day (Including snacks)',
      question: 'numberOfMeals',
      defaultValue: 3,
      options: mealsPerDay,
    },
    {
      label: 'Diet type',
      question: 'dietType',
      defaultValue: 'Standard',
      options: dietType,
    },
    {
      label: 'Largest meal of the day',
      question: 'largestMeal',
      defaultValue: 'Distribute equally',
      options: largestMeal,
    },
  ];

  const handleGenerateMealPlan = () => {
    const scrollableElement = document.documentElement || document.body;

    scrollableElement.scrollTo({
      top: 0,
    });

    if (!auth.isAuthenticated) {
      setIsGeneratingMealPlan(true);
    }

    const timeoutDuration = auth.isAuthenticated ? 0 : 6000;

    setTimeout(() => {
      let mealPlan = generateMealPlan(
        selectedMacros,
        mealPlanAnswers.dietType,
        mealPlanAnswers.numberOfMeals,
        mealPlanAnswers.largestMeal
      );

      navigate('/tools/macronutrient-calculator/results/custom-meal-plan', {
        state: { mealPlan, dietType: mealPlanAnswers.dietType },
      });

      if (!auth.isAuthenticated) {
        setIsGeneratingMealPlan(false);
        dispatch({ type: 'OPEN_AUTH_MODAL', payload: true });
      }
    }, timeoutDuration);
  };

  const sortedGoals = Object.entries(macroResults.macrosForEachGoal).sort(
    ([, goalA], [, goalB]) =>
      goalA.isSelected === goalB.isSelected ? 0 : goalA.isSelected ? -1 : 1
  );

  const recommendedGoal = sortedGoals.find(([, goal]) => goal.isSelected);
  const otherGoals = sortedGoals.filter(([, goal]) => !goal.isSelected);

  return isGeneratingMealPlan ? (
    <MealPlanLoading onComplete={() => setIsGeneratingMealPlan(false)} />
  ) : (
    <motion.div
      className='macro-results-page'
      initial='hidden'
      animate='visible'
      variants={containerVariants}
    >
      <Modal
        maskStyle={{
          backgroundColor: 'black',
          opacity: '0.8',
        }}
        className='generate-mealplan-modal dark'
        open={modalVisible}
        footer={null}
        closable={true}
        onCancel={() => setModalVisible(false)}
        centered={true}
      >
        <div className='mb-20 mt-10'>{renderSelectedGoalAndMacros()}</div>
        <div>
          <p className='label'>Macronutrient Preference</p>
          <Select
            className='mb-20'
            options={items}
            defaultValue='balanced'
            style={{
              width: '100%',
              border: '1px solid transparent',
            }}
            onChange={(value) => setMacroPreference(value)}
            value={macronutrientAnswers.macroPreference || null}
          />
          <p className='subtitle mb-20' style={{ color: 'white' }}>
            {
              items.find(
                (item) => item.value === macronutrientAnswers.macroPreference
              ).explanation
            }
          </p>
        </div>
        {renderCustomMacrosInput()}
        {mealPlanQuestions.map((question) => (
          <div>
            <p className='label'>{question.label}</p>
            <Select
              className='mb-20'
              options={question.options}
              defaultValue={question.defaultValue}
              value={mealPlanAnswers[question.question].value}
              style={{
                width: '100%',
                border: '1px solid transparent',
              }}
              onChange={(value) =>
                setMealPlanAnswers({
                  ...mealPlanAnswers,
                  [question.question]: value,
                })
              }
            />
          </div>
        ))}
        <Button
          type='primary'
          size='large'
          className='landing-button full-width-button gradient custom-cursor'
          onClick={() => handleGenerateMealPlan()}
          style={{
            height: '80px',
            padding: '0 40px',
            fontSize: '20px',
          }}
        >
          Generate Your Meal Plan
        </Button>
      </Modal>

      {macroResults && (
        <div className='landing-page black-background'>
          <motion.div
            className='results-container'
            variants={containerVariants}
          >
            <motion.div
              className='flex align-center space-between mb-20'
              variants={itemVariants}
            >
              <p className='small-title text-center text-gradient'>
                {t('results.congrats')}
              </p>
              <div className='flex align-center' style={{ cursor: 'pointer' }}>
                <IoIosArrowBack
                  style={{ color: 'white', marginRight: '3px' }}
                />
                <p
                  className='subtitle white '
                  onClick={() => navigate('/tools/macronutrient-calculator')}
                >
                  {t('buttons.calculateAgain')}
                </p>
              </div>
            </motion.div>
            <motion.div
              className='global-card dark mb-10'
              variants={itemVariants}
            >
              <div className='mb-20'>
                <div className='grid'>
                  <p className='eyebrow-text blue mb-10'>
                    Your Macros For{' '}
                    {capitalize(recommendedGoal[0].split('-').join(' '))}{' '}
                    {`${recommendedGoal[0] !== 'maintain-weight' ? '~' : ''}`}
                    {recommendedGoal[1].description}
                  </p>
                </div>
              </div>

              <div className='mb-20'>
                <div className='grid gap-10'>
                  {recommendedGoal && (
                    <div
                      key={recommendedGoal[0]}
                      className='global-card dark'
                      style={{
                        position: 'sticky',
                        top: '20px',
                        zIndex: 10,
                        border: '1px solid #f9f9f9',
                        borderRadius: '20px',
                        background:
                          'linear-gradient(90deg, rgba(39, 97, 241, 0.2), rgba(255, 0, 162, 0.2))',
                        padding: `${isMobileView ? '18px 10px' : '18px'}`,
                      }}
                    >
                      <span
                        className='yellow-label recommended'
                        style={{ width: isMobileView && '100%' }}
                      >
                        ✨Recommended For You✨
                      </span>
                      <div>
                        <div className='flex space-between'>
                          <div className='grid justify-center'>
                            <p className='macro-number bold'>
                              {recommendedGoal[1].calories}
                            </p>
                            <p className='subtitle'>Calories</p>
                          </div>
                          <div className='grid justify-center'>
                            <p className='macro-number bold'>
                              {recommendedGoal[1].protein}g
                            </p>
                            <p className='subtitle'>Protein</p>
                          </div>
                          <div className='grid justify-center'>
                            <p className='macro-number bold'>
                              {recommendedGoal[1].carbs}g
                            </p>
                            <p className='subtitle'>Carbs</p>
                          </div>
                          <div className='grid justify-center'>
                            <p className='macro-number bold'>
                              {recommendedGoal[1].fat}g
                            </p>
                            <p className='subtitle'>Fat</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {showAll &&
                    otherGoals.map(([key, goal]) => (
                      <div
                        key={key}
                        className='global-card dark'
                        style={{
                          position: 'relative',
                          padding: '18px',
                          borderRadius: '20px',
                        }}
                      >
                        <div>
                          <div className='grid'>
                            <p className='eyebrow-text blue mb-10'>
                              {capitalize(key.split('-').join(' '))}{' '}
                              {`${key !== 'maintain-weight' ? '~' : ''}`}
                              {goal.description}
                            </p>
                          </div>

                          <div className='flex space-between'>
                            <div className='grid justify-center'>
                              <p className='macro-number bold'>
                                {goal.calories}
                              </p>
                              <p className='subtitle'>Calories</p>
                            </div>
                            <div className='grid justify-center'>
                              <p className='macro-number bold'>
                                {goal.protein}g
                              </p>
                              <p className='subtitle'>Protein</p>
                            </div>
                            <div className='grid justify-center'>
                              <p className='macro-number bold'>{goal.carbs}g</p>
                              <p className='subtitle'>Carbs</p>
                            </div>
                            <div className='grid justify-center'>
                              <p className='macro-number bold'>{goal.fat}g</p>
                              <p className='subtitle'>Fat</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  {/* {otherGoals.length > 0 && !showAll && (
                    <div
                      onClick={() => setShowAll(true)}
                      style={{
                        cursor: 'pointer',
                        textAlign: 'center',
                        color: '#2761f1',
                        fontWeight: 'bold',
                        marginTop: '10px',
                      }}
                    >
                      See Other Options
                    </div>
                  )} */}

                  {/* {showAll && (
                    <div
                      onClick={() => setShowAll(false)}
                      style={{
                        cursor: 'pointer',
                        textAlign: 'center',
                        color: '#2761f1',
                        fontWeight: 'bold',
                        marginTop: '10px',
                      }}
                    >
                      See Less
                    </div>
                  )} */}
                </div>
              </div>
              {/* <div className='mb-20'>
                <p className='eyebrow-text' style={{ color: '#2761f1' }}>
                  Your Macronutrient Breakdown
                </p>
                <p className='subtitle'>
                  Choose your preferred diet type to tailor your macros to your
                  goals.
                </p>
              </div>
              <div>
                <Select
                  className='mb-20'
                  options={items}
                  defaultValue='balanced'
                  style={{
                    width: 300,
                    border: '1px solid transparent',
                  }}
                  onChange={(value) => setMacroPreference(value)}
                  value={macronutrientAnswers.macroPreference || null}
                />
                <p className='subtitle mb-20'>
                  {
                    items.find(
                      (item) =>
                        item.value === macronutrientAnswers.macroPreference
                    ).explanation
                  }
                </p>
              </div>
              {renderCustomMacrosInput()} */}
              {/* {renderSelectedGoalAndMacros()} */}

              <div>
                <Button
                  onClick={() => setModalVisible(true)}
                  size='large'
                  type='primary'
                  className='landing-button full-width-button gradient generate-meal-plan custom-cursor'
                >
                  Generate Your Meal Plan
                </Button>
              </div>
            </motion.div>
            <motion.div
              className='global-card dark clickable mb-10'
              variants={itemVariants}
              onClick={() =>
                setCardOpen({ ...cardOpen, bmr: !cardOpen['bmr'] })
              }
            >
              <div className='flex space-between align-center'>
                <p className='eyebrow-text blue mb-10'>
                  Your BMR (Basal Metabolic Rate)
                </p>
                <IoIosArrowDown className='mb-10' />
              </div>
              <p
                className='macro-number bold'
                style={{ marginBottom: cardOpen['bmr'] && '10px' }}
              >
                {macroResults.bmr}
              </p>

              {cardOpen['bmr'] && (
                <p className='subtitle'>
                  BMR represents the number of calories your body needs to
                  perform basic functions like breathing, circulation, and cell
                  production while at rest. It's the minimum amount of energy
                  required to sustain life, and it varies based on factors like
                  age, weight, gender, and muscle mass.
                </p>
              )}
            </motion.div>

            <motion.div
              className='global-card dark clickable mb-10'
              variants={itemVariants}
              onClick={() =>
                setCardOpen({ ...cardOpen, tdee: !cardOpen['tdee'] })
              }
            >
              <div className='flex space-between align-center'>
                <p className='eyebrow-text blue mb-10'>
                  Your TDEE (Total Daily Energy Expenditure)
                </p>
                <IoIosArrowDown />
              </div>
              <p
                className='macro-number bold'
                style={{ marginBottom: cardOpen['tdee'] && '10px' }}
              >
                {macroResults.maintenanceCalories}
              </p>
              {cardOpen['tdee'] && (
                <p className='subtitle'>
                  TDEE is the total number of calories your body burns in a day,
                  including all physical activities such as exercise, walking,
                  and even digestion. It combines your BMR with your activity
                  level to determine how many calories you need to maintain your
                  current weight.
                </p>
              )}
            </motion.div>
          </motion.div>
        </div>
      )}
    </motion.div>
  );
};
